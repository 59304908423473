import React, { useState } from "react";
import styled from "styled-components"
import PromotionLayout from "../../container/PromotionLayout"
import Youtube from '../../assets/svg/youtube.svg';
import { Link, useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"


const DescriptionLink = styled.h3`
  display: flex;
  align-items: center;
  margin: 0 0 50px;
`;

const Description = styled(Link)`
  color: #000000;
  text-decoration: none;
`

const YoutubeIcon = styled(Youtube)`
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 15px;
`;

const ThumbnailGrid = styled.div`
  display: grid;
  width: 700px;
  grid-template-columns: repeat(3, 200px);
  grid-row-gap: 30px;
  grid-column-gap: 50px;
  margin: 0 auto;
  
  @media screen and (max-width: 768px) {
    width: 100%;
    grid-template-columns: 1fr;
  }
`;

const ThumbnailContainer = styled.div`
  cursor: pointer;
  text-align: center;
  margin-top: 80px;
  @media screen and (max-width: 768px) {
  margin-top: 0;
    border-bottom: 10px solid var(--first-color);
  }
`;


const VideoContainer = styled.div`
  border: 10px solid var(--first-color);
  display: flex;
  padding: 50px;
  
  @media screen and (max-width: 768px) {
    border: 0;
    padding: 0;
    flex-wrap: wrap;
  }
`;
const VideoTitle = styled.div`
  height: 50px;
  font-weight: bold;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;
const VideoDescription = styled.div`
  margin-left: auto;
  width: 350px;
  overflow-y: scroll;
  padding: 20px 50px;
  
  @media screen and (max-width: 768px) {
    padding: 20px 0 50px;
  }
`;

const Thumbnail = styled(GatsbyImage)`

  @media screen and (max-width: 768px) {
    display: none !important;
  }
`

function PromotionVideoPage () {
  const [videoIndex, setVideoIndex] = useState(0);
  const data = useStaticQuery(graphql`
      query {
          allVideoJson {
              edges {
                  node {
                      name
                      video
                      description
                      thumbnail
                  }
              }
          }
          firstImage: file(relativePath: { eq: "video_1.jpg" }) {
              childImageSharp {
                  fixed(width: 200) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          secondImage: file(relativePath: { eq: "video_2.jpg" }) {
              childImageSharp {
                  fixed(width: 200) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          thirdImage: file(relativePath: { eq: "video_3.jpg" }) {
              childImageSharp {
                  fixed(width: 200) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          fourthImage: file(relativePath: { eq: "video_4.jpg" }) {
              childImageSharp {
                  fixed(width: 200) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          fifthImage: file(relativePath: { eq: "video_5.jpg" }) {
              childImageSharp {
                  fixed(width: 200) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
      }
  `)
  const handleThumbnailClick = index => {
    setVideoIndex(index)
    window.scrollTo(0, 0);
  }
  const {name, video, description} = data.allVideoJson.edges[videoIndex].node;

  return (
    <PromotionLayout title="관련 동영상">
      <DescriptionLink>
        <YoutubeIcon />
        <Description to="https://www.youtube.com/channel/UCTZnL0KIDfYYVKUJ08LZEPg">
          동아특수건설 Youtube
        </Description>
      </DescriptionLink>
      <VideoTitle>{name}</VideoTitle>
      <VideoContainer>
        <iframe
          title={name}
          width="480"
          height="270"
          src={video}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen/>
        <VideoDescription>
          {description}
        </VideoDescription>
      </VideoContainer>
      <ThumbnailGrid>
        {data.allVideoJson.edges.map(({ node }, index) => (
          <ThumbnailContainer
            key={node.name}
            onClick={() => handleThumbnailClick(index)}>
            { data[node.thumbnail] &&
              <Thumbnail fixed={data[node.thumbnail].childImageSharp.fixed} />
            }
            {node.name}
          </ThumbnailContainer>
        ))}
      </ThumbnailGrid>
    </PromotionLayout>
  )
}

export default PromotionVideoPage;